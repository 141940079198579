import DraftActions from 'app/reducer/draftRedux';
import { makeCallbacks, makeOpinionParams } from './helper';

const getOpinionDrafts = ({ id }, meta = {}) => {
  return DraftActions.getOpinionDraftsRequest({ id }, meta);
};

const getOpinionDraft = ({ slug }, meta = {}) => {
  return DraftActions.getOpinionRequest({ slug }, meta);
};

const saveOpinionDraft = (
  {
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    image,
  },
  { onSuccess, onFailure } = {},
) => {
  const meta = {};
  const params = makeOpinionParams({
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    image,
  });
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return DraftActions.saveOpinionDraftRequest(params, meta);
};

const updateOpinionDraft = (
  {
    id,
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    image,
  },
  { onSuccess, onFailure } = {},
) => {
  const meta = {};
  const params = makeOpinionParams({
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    image,
  });
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return DraftActions.updateOpinionDraftRequest({ ...params, id }, meta);
};

const deleteOpinionDraft = ({ id }, { onSuccess, onFailure } = {}) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return DraftActions.deleteOpinionDraftRequest({ id }, meta);
};

const setEditOpinionDraftId = opinionId => {
  return DraftActions.setEditOpinionDraftId(opinionId);
};

export {
  getOpinionDrafts,
  getOpinionDraft,
  saveOpinionDraft,
  updateOpinionDraft,
  deleteOpinionDraft,
  setEditOpinionDraftId,
};
