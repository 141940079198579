import React from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';

import { deleteOpinion } from 'app/dispatcher/opinionDispatcher';
import { deleteOpinionDraft } from 'app/dispatcher/draftDispatcher';
import {
  closeDeleteDraftModal,
  closeDeleteOpinionModal,
} from 'dispatcher/modalDispatcher';

import Button from 'seedly-component-library/button-new';
import { RootState } from 'app/reducer';
import Dialog from 'seedly-component-library/dialog';

const DeleteOpinionModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isDeleteOpinionModalOpen = useSelector(
    (state: RootState) => state.modal.isDeleteOpinionModalOpen,
  );
  const isDeleteDraftModalOpen = useSelector(
    (state: RootState) => state.modal.isDeleteDraftModalOpen,
  );
  const id = useSelector(
    (state: RootState) => state.modal.deleteOpinionModalMeta.id,
  );
  return (
    <Dialog isOpen={isDeleteOpinionModalOpen || isDeleteDraftModalOpen}>
      <div className="flex flex-col w-full items-center gap-4">
        <p className="text-xl text-center font-bold">
          {`Are you sure you want to delete ${
            isDeleteDraftModalOpen ? 'draft' : 'opinion'
          }?`}
        </p>
        <p>Opinion will be permanently deleted</p>
        <div className="flex gap-2 items-center">
          <Button
            variant="outlineGrey"
            onClick={() => {
              if (isDeleteDraftModalOpen) {
                dispatch(closeDeleteDraftModal());
              } else {
                dispatch(closeDeleteOpinionModal());
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (isDeleteDraftModalOpen) {
                dispatch(
                  deleteOpinionDraft(
                    { id },
                    {
                      onSuccess: () => {
                        router.replace('/drafts');
                      },
                      onFailure: () => {},
                    },
                  ),
                );
                dispatch(closeDeleteDraftModal());
              } else {
                dispatch(
                  deleteOpinion(
                    { id },
                    {
                      onSuccess: () => {
                        router.replace('/community');
                      },
                      onFailure: () => {},
                    },
                  ),
                );
                dispatch(closeDeleteOpinionModal());
              }
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteOpinionModal;
