import OpinionActions from 'app/reducer/opinionRedux';
import { makeCallbacks, makeOpinionParams } from './helper';

const getOpinion = ({ opinionSlug }, meta = {}) => {
  return OpinionActions.getOpinionRequest({ opinionSlug }, meta);
};

const getOpinions = (params, meta = {}) => {
  if (params.page && params.page > 1) {
    meta.isFetchingMoreOpinions = true;
  }
  return OpinionActions.getOpinionsRequest(params, meta);
};

const getUserOpinions = ({ slug }, meta = {}) => {
  return OpinionActions.getUserOpinionsRequest({ slug }, meta);
};

const getUserOpinionsNextPage = ({ slug, pagination }, meta = {}) => {
  const { currentPage } = pagination;
  const page = currentPage + 1;
  meta = { isFetchingMoreOpinions: true };
  return OpinionActions.getUserOpinionsRequest({ slug, page }, meta);
};

const getBookmarkedOpinions = ({ slug }, meta = {}) => {
  return OpinionActions.getBookmarkedOpinionsRequest({ slug }, meta);
};

const getBookmarkedOpinionsNextPage = ({ slug, pagination }, meta = {}) => {
  const { currentPage } = pagination;
  const page = currentPage + 1;
  meta = { isFetchingMoreOpinions: true };
  return OpinionActions.getBookmarkedOpinionsRequest({ slug, page }, meta);
};

const postOpinion = (
  {
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    draftId,
    image,
  },
  { onSuccess, onFailure } = {},
) => {
  const meta = {};
  const params = makeOpinionParams({
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    draftId,
    image,
  });
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return OpinionActions.postOpinionRequest(params, meta);
};

const updateOpinion = (
  {
    id,
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    image,
  },
  { onSuccess, onFailure } = {},
) => {
  const meta = {};
  const params = makeOpinionParams({
    title,
    description,
    content,
    credentialId,
    topics,
    tempImageIds,
    tempBgIds,
    image,
  });
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return OpinionActions.updateOpinionRequest({ ...params, id }, meta);
};

const deleteOpinion = ({ id }, { onSuccess, onFailure } = {}) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return OpinionActions.deleteOpinionRequest({ id }, meta);
};

const setEditOpinionId = opinionId => {
  return OpinionActions.setEditOpinionId(opinionId);
};

const voteOpinion = ({ id, voteType }, { onSuccess, onFailure } = {}) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return OpinionActions.voteOpinionRequest({ id, voteType }, meta);
};

const bookmarkOpinion = ({ id }, { onSuccess, onFailure } = {}) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return OpinionActions.bookmarkOpinionRequest({ id }, meta);
};

const updateOpinionPageView = (id, { onSuccess, onFailure } = {}) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return OpinionActions.updateOpinionPageViewRequest({ id }, meta);
};

export {
  postOpinion,
  updateOpinion,
  deleteOpinion,
  getOpinion,
  getOpinions,
  getUserOpinions,
  getUserOpinionsNextPage,
  getBookmarkedOpinions,
  getBookmarkedOpinionsNextPage,
  voteOpinion,
  setEditOpinionId,
  bookmarkOpinion,
  updateOpinionPageView,
};
